import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Current',
    component: () => import('../views/current.vue')
  },
  {
    path: '/next',
    name: 'Next',
    component: () => import('../views/weekly.vue')
  },
  {
    path: '/im',
    name: 'IM',
    component: () => import('../views/im.vue')
  },
  {
    path: '/weekly',
    redirect: '/'
  },
  {
    path: '/crcdm',
    name: 'CRCDM',
    component: () => import('../views/crcdm.vue')
  },
  {
    path: '/variety',
    name: 'Variety',
    component: () => import('../views/variety.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../views/notfound')

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
